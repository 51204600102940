import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import dataProvider from '../../dataProvider';
import Course from '../../types/Course';

import {
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function CourseCard({ data }: { data: any }) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.2)",
        borderRadius: "10px",
        width: "300px",
        height: "580px",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={data.img || "https://via.placeholder.com/300"}
        alt={data.name || "Course name"}
      />
      <CardContent 
        style={{
          height: "335px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={1.5}
          direction="column"
          justifyContent={"space-between"}
          // sx={{ height: "100%" }}
          divider={<Box sx={{ border: "1px solid #e0e0e0" }} />}
        >
          <Typography component="div"
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#134847",
          }}
          >
            {data.name || "Course name"}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography style={{ fontSize: "20px", color: "#134847" }}>
              الحد الادني للساعات
            </Typography>
            <Typography style={{ fontSize: "20px", color: "#f7941d" }}>
              {data.hours || "0"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography style={{ fontSize: "20px", color: "#134847" }}>
              السعر للساعة
            </Typography>

            <Typography style={{ fontSize: "20px", color: "#f7941d" }}>
              {data.pricePerHour || "0.0"}
            </Typography>
          </Box>

          <Typography sx={{ mt: 1, 
            fontSize: "18px", 
            color: "#134847", 
            textAlign: "start",
            // max 3 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            
            
            }}>
            {data.description || "Course Description"}
          </Typography>

        </Stack>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            {/* <Button variant="contained" style={{ backgroundColor: "#f7941d", color: "#fff", fontSize: "20px", 
            width: "120px"

            }}>
              تفاصيل
            </Button> */}
            <Button
              variant="contained"
              style={{
                backgroundColor: "#f7941d",
                color: "#fff",
                fontSize: "20px",
                width: "120px",
              }}
              component={Link}
              to={`/courses/${data.id}`}
            >
              تفاصيل
            </Button>
            <Button variant="contained" style={{ backgroundColor: "#fff5d7", color: "#134847", fontSize: "20px",
            width: "120px"

             }}
             onClick={() => navigate(`/trainer-profile/${data.id}/edit`)}
            //  onClick={() => navigate(`/edit-course-availability/${data.id}`)}
             >
              {/* {data.totalPrice || "0.0"} */}
              تعديل الوقت
            </Button>
          </Box>
      </CardContent>
    </Card>
  );
}



export default function TrainerProfileOverviewPage() {
  const [courses, setCourses] = React.useState<Array<Course>>([]);
  React.useEffect(() => {
    (async () => {
      try {
        const res = await dataProvider.courses.getAll();
        console.log(res);
        setCourses(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  return (
    <div>
      <h2>TrainerProfileOverviewPage</h2>
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap">
        {
          courses.map((course, index) => (
            <CourseCard data={course} key={index} />
          ))
        }
      </Stack>
    </div>
  );
}