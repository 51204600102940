function convertToArabicDate(timestamp: string) {
    // Define Arabic months
    const arabicMonths = [
      "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];
  
    // Create a Date object from the timestamp
    const date = new Date(timestamp);
  
    // Extract day, month, and year
    const day = date.getUTCDate();
    const month = arabicMonths[date.getUTCMonth()];
    const year = date.getUTCFullYear();
  
    // Return the formatted date
    return `${day} ${month} ${year}`;
  }
  
//   // Example usage
//   const timestamp = "2024-11-26T19:51:27.206Z";
//   console.log(convertToArabicDate(timestamp)); // Output: 26 نوفمبر 2024
export default convertToArabicDate;