import fetcher from "../utils/fetcher";
const createModelCRUD = (model: string) => ({
    create: async (data: any) => {
        return await fetcher({
            url: `/${model}`,
            method: "POST",
            data,
        });
    },
    getAll: async (query: Record<string, any> = {}) => {
        const queryString = new URLSearchParams(query).toString();
        let url = `/${model}/getall${queryString ? `?${queryString}` : ""}`;
        if (model === "coursetype") {
            url = `/${model}${queryString ? `?${queryString}` : ""}`;
        }
        return await fetcher({
            url: url,
            method: "GET",
        });
    },
    getOne: async (id?: string | number) => {
        const url = id ? `/${model}/get?id=${id}` : `/${model}/get`;
        return await fetcher({
            url: url,
            method: "GET",
        });
    },
    update: async (id: string | number, data: any) => {
        return await fetcher({
            url: `/${model}/${id}`,
            method: "PUT",
            data,
        });
    },
    delete: async (id: string | number) => {
        return await fetcher({
            url: `/${model}/${id}`,
            method: "DELETE",
        });
    },
});


const dataProvider = {
    auth:{
        login: async (data: {email: string, password: string}) => {
            return await fetcher({ 
                url: "/auth/login", 
                method: "POST", 
                data: data
            });
        },
        // /api/auth/forget-password
        forgetPassword: async (data: {email: string}) => {
            return await fetcher({ 
                url: "/auth/forget-password", 
                method: "POST", 
                data: data
            });
        },
        change: async (data: {password: string, newPassword: string}) => {
            return await fetcher({ 
                url: "/auth/change-password", 
                method: "POST", 
                data: data
            });
        },
        blabla: async (data: any) => {
            return await fetcher({ 
                url: "/auth/blabla", 
                method: "POST", 
                data: data
            });
        },
    },
    students: createModelCRUD("students"),
    trainer: {
        // /api/trainer/gettrainersessions
        getTrainerSessions: async (params: Record<string, any> = {}) => {
            return await fetcher({
                url: `/trainer/gettrainersessions`,
                method: "GET",
                params: params,
            });
        },
        ...createModelCRUD("trainer")
    },
    // coursetype
    courses: {
        ...createModelCRUD("coursetype"),
        getAll: async (query: Record<string, any> = {}) => {
            return {
                data: [
                // قرأن كريم
                {
                    "id": "1",
                    "name": "قرأن كريم",
                    "description": "قرأن كريم",
                    "hourRate": 0,
                    "minimumAllowedHours": 0,
                    "img": "https://via.placeholder.com/300",
                },
                // علوم الشريعة
                {
                    "id": "2",
                    "name": "علوم الشريعة",
                    "description": "علوم الشريعة",
                    "hourRate": 0,
                    "minimumAllowedHours": 0,
                    "img": "https://via.placeholder.com/300",
                },
                // قواعد التجويد
                {
                    "id": "3",
                    "name": "قواعد التجويد",
                    "description": "قواعد التجويد",
                    "hourRate": 0,
                    "minimumAllowedHours": 0,
                    "img": "https://via.placeholder.com/300",
                },
                // شرح صحيح مسلم
                {
                    "id": "4",
                    "name": "شرح صحيح مسلم",
                    "description": "شرح صحيح مسلم",
                    "hourRate": 0,
                    "minimumAllowedHours": 0,
                    "img": "https://via.placeholder.com/300",
                }
                
                 ]
            };
        }
    },
    // courses: createModelCRUD("courses"),
    transactions: createModelCRUD("transactions"),
    categories: createModelCRUD("categories"),
    auditlog: createModelCRUD("auditlog"),
    blogs: {
        ...createModelCRUD("blogs"),
        getAll: async (query: Record<string, any> = {}) => {
            return {
                data: [
                    // title: string;
                    // author: string;
                    // description: string;
                    // img: string;
                    // createdAt: string;
                    {
                        "id": "1",
                        "title": "قرأن كريم",
                        author: "محمد",
                        "description": "قرأن كريم",
                        "img": "https://via.placeholder.com/300",
                        "createdAt": "2021-09-01",
                    },
                ],
                page: 1,
                pageSize: 10,
            }
        }
    },
    prayerTimes: async () => {
        return await fetcher({
            url: "/prayer-times",
            method: "GET",
        });
    },

};
export default dataProvider;
