import { Button, Card, CardContent, CardMedia, Typography, Stack } from "@mui/material";
import convertToArabicDate from "../utils/convertToArabicDate";
function ArticleCard({ data }: { data: any }) {
    return (
      <Card sx={{ width: 370, height: 570 }}>
        <CardMedia
          component="img"
          height="200"
          image={data.img}
          alt={data.title}
        />
        <CardContent
          style={{
            height: "330px",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            height="100%"
          >

          <Stack>

          <Typography variant="body2" color="textSecondary" style={{ fontSize: "18px" }}>
            {convertToArabicDate(data.createdAt)}
          </Typography>
          <Typography variant="h6" style={{ fontSize: "25px" }}>
            {data.title}
          </Typography>
          <Typography variant="subtitle2" color="orange" style={{ fontSize: "18px" }}>
            {data.author}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ fontSize: "18px" }}>
            {data.description}
          </Typography>
          </Stack>

          <Button variant="contained" color="warning" sx={{ width: "132px", height: "50px" }}>
            Read More
          </Button>
          </Stack>


        </CardContent>
      </Card>
    );
}
export default ArticleCard;
