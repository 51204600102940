
import { Box, Button, Typography, Stack } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import bgImg from '../assets/bg_c.png';


const QuranLearningSection = () => {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '600px',
          backgroundImage: `url(${bgImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          overflow: 'hidden',
          width: '100vw',
        }}
      >
        {/* Dark overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100%',
            // bgcolor: 'rgba(0, 0, 0, 0.6)', // Dark overlay for readability
            zIndex: 1,
          }}
        />
  
        {/* Content */}
        <Stack
          alignItems="center"
          justifyContent="space-between"
          // spacing={30}
          direction="row"
          sx={{
            width: '100vw',
            position: 'relative',
            zIndex: 2,
            // gap: 38,
            // px: 15,
            padding: '0 10%',
          }}
        >
         
  
          {/* Text content */}
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: "50px"  }}>
              منصة حافظ
            </Typography>
            <Typography sx={{ mt: 2, maxWidth: '40vw', fontSize: "30px",
              textAlign: 'justify',
             }}>
              أنشئت لتعليم القرآن الكريم و أحكام التلاوة
              في أي مكان بالعالم من خلال الإنترنت
              وبمشاركة من المعلمين الأكفاء, الذي يعمل
              وفق أفضل الطرق التربوية وأحدث الوسائل
              التعليمية التي تناسب جميع المستويات
              والقدرات سعيًا دائماً لتيسير طلابها،
              والمحافظة على أوقاتهم ورفع مهاراتهم.
            </Typography>
            <Button
              variant="contained"
              // height="60px"
              // width="240px"
              sx={{
                width: '240px',
                height: '60px',
                fontSize: '20px',
                fontWeight: 'bold',
                mt: 2,
                bgcolor: '#f7941d',
                '&:hover': {
                  bgcolor: '#f5731d',
                },
              }}
            >
              طلب حصة تجريبية
            </Button>
          </Box>
  
           {/* Play button */}
           <Button
            sx={{
              minWidth: '120px',
              height: '120px',
              bgcolor: '#f7941d',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: '#f5731d',
              },
            }}
          >
            <PlayCircleIcon sx={{ fontSize: 60, color: 'white' }} />
          </Button>
  
  
        </Stack>
      </Box>
    );
  };

  export default QuranLearningSection;