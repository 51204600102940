import { Box, Typography, Card, CardContent, CardMedia, Button, Grid, IconButton } from '@mui/material';

import SectionHeader from "./SectionHeader"
import TeacherCard from "./TeacherCard"
import dataProvider from '../dataProvider';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


type Teacher = {
  name: string;
  title: string;
  description: string;
  img: string;
}

const TeachersSection = () => {
  const [teachers, setTeachers] = useState<Teacher[] | []>([]);

  useEffect(() => {
    (async () => {
      const data = await dataProvider.trainer.getAll({
        pageSize: 3,
      });
      setTeachers(data.data);
    })();
  }, []);

  return (
    <Box sx={{ textAlign: 'center', mt: 4, py: 4, backgroundColor: '#f9f2e7', width: '100vw' }}>
      <SectionHeader headerText="أفضل المعلميين" />


      <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
        {teachers.map((teacher, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}
          style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TeacherCard data={teacher} />
          </Grid>
        ))}
      </Grid>

      <Button variant="contained" color="warning" sx={{ mt: 6 }}
      component={Link} to="/teachers"
      >
        عرض المزيد
      </Button>
    </Box>
  );
};

export default TeachersSection;
