import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Grid, 
  IconButton,
  Select,
  FormControl,
  InputLabel,

} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import dataProvider from '../../dataProvider';
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useMemo } from "react";


// Define the validation schema using Yup
const validationSchema = Yup.object({
  // len8 1 Capital 1 small 1 number 1 special char
  // courseName: Yup.string(),
  duration: Yup.string(),

});

function FormField({ propId ,placeholder, label, type="text", items=[] }: any){
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
    maxWidth: "300px",

  };

  const textFieldStyle = {
    width: "100%",
    maxWidth: "300px",
    // marginBottom: "20px",
    minWidth: "300px",
    // minWidth: "250px",

  };
  if (type === "select") {
    return (
    <Stack direction="column">
      
      <Box component="label" sx={labelStyle} htmlFor={propId}>
  {label}
</Box>
<Field name={propId}>
  {({ field }: FieldProps) => (
    <TextField
      {...field}
      id={propId}
      variant="outlined"
      select
      placeholder={placeholder}
      sx={textFieldStyle}
      SelectProps={{
        native: false, // Ensure it's a MUI Select, not a native dropdown
      }}
    >
      {items.map((item: any) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )}
</Field>
<ErrorMessage name={propId}>
  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
</ErrorMessage>
      </Stack>
    )
  }

  return (
    <Stack direction="column">
    <Box component="label" sx={labelStyle} htmlFor={propId}>
      {label}
    </Box>
    <Field name={propId}>
      {({ field }: FieldProps) => (
        <TextField
          {...field}
          id={propId}
          variant="outlined"
          type={type}
          placeholder={placeholder}
          sx={textFieldStyle}
        />
      )}
    </Field>
    <ErrorMessage name={propId}>
      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
    </ErrorMessage>
    </Stack>
  )
}


const LoginForm: React.FC = () => {

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",
    maxWidth: "300px",
  };

  function handleSubmit(values: any, { setSubmitting }: any) {
    console.log(values)
    dataProvider.auth.change({ password: values.password, newPassword: values.newPassword }).then((res) => {
      console.log(res);
      setSubmitting(false);
    }).catch((err) => {
      console.log(err);
      setSubmitting(false);
    })
      
  }

  return (
    <Formik
      initialValues={{ 
        courseName: '',
        duration: '',
       }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Stack direction="row"  width="100%" spacing={5} 
          // justifyContent="center"
           alignItems="center" useFlexGap flexWrap="wrap">
            <FormField  propId="courseName" placeholder="ادخل اسم الكورس" label="اسم الكورس" />
            <FormField  propId="duration" placeholder="ادخل مدة الحصة" label="مدة الحصة" type="select" items={[
              { label: "30 دقيقة", value: '30' },
              { label: "60 دقيقة", value: '60' },
            ]} />
          </Stack>

          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            حفظ
          </Button>
          
          
        </Form>
      )}
    </Formik>
  );
};



const FormExample = () => {
  const formik = useFormik({
    initialValues: {
      duration: "30", // Default duration
      suitableTimes: [
        { from: "", to: "", day: "Saturday" },
        { from: "", to: "", day: "Sunday" },
        { from: "", to: "", day: "Monday" },
      ],
    },
    onSubmit: (values) => {
      console.log("Form values", values);
    },
  });

  // Generate time options dynamically based on duration
  const generateTimeOptions = (interval: any) => {
    const options = [];
    let currentHour = 8; // Start time: 8:00 AM
    let currentMinute = 0; // Start time: 00 minutes

    while (currentHour < 20) {
      const time = `${currentHour.toString().padStart(2, "0")}:${currentMinute
        .toString()
        .padStart(2, "0")} ${currentHour < 12 ? "AM" : "PM"}`;
      options.push(time);

      currentMinute += interval;
      if (currentMinute >= 60) {
        currentMinute = 0;
        currentHour++;
      }
    }
    return options;
  };

  const timeOptions = useMemo(() => generateTimeOptions(Number(formik.values.duration)), [formik.values.duration]);

  const dayOptions = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];

 

  const handleAddRow = () => {
    formik.setFieldValue("suitableTimes", [
      ...formik.values.suitableTimes,
      { from: "", to: "", day: "" },
    ]);
  };

  const handleRemoveRow = (index: any) => {
    const updatedTimes = [...formik.values.suitableTimes];
    updatedTimes.splice(index, 1);
    formik.setFieldValue("suitableTimes", updatedTimes);
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} p={3}>
      <Grid container spacing={2}>
        {/* Duration Field */}
        <Grid item xs={12} sm={6}>
          {/* standard,  filled*/}
          {/* variant="" */}
          <FormControl  fullWidth>
            <InputLabel>مدة الجلسة</InputLabel>
            <Select
              name="duration"
              value={formik.values.duration}
              onChange={formik.handleChange}
            >
              <MenuItem value="30">30 دقيقة</MenuItem>
              <MenuItem value="60">60 دقيقة</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        
        {/* Suitable Times Section */}
        <Grid item xs={12}>
          <h3>حدد المواعيد المناسبة</h3>
        </Grid>
        {formik.values.suitableTimes.map((time, index) => (
          <Grid container spacing={2} key={index} pb={3}>
            {/* From Time */}
            <Grid item xs={3} >
              <FormControl fullWidth>
                <InputLabel>من الساعة</InputLabel>
                <Select
                  name={`suitableTimes[${index}].from`}
                  value={time.from}
                  onChange={formik.handleChange}
                >
                  {timeOptions.map((option:any, idx: number) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* To Time */}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>إلى الساعة</InputLabel>
                <Select
                  name={`suitableTimes[${index}].to`}
                  value={time.to}
                  onChange={formik.handleChange}
                >
                  {timeOptions.map((option: any, idx: number) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Day */}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>اليوم</InputLabel>
                <Select
                  name={`suitableTimes[${index}].day`}
                  value={time.day}
                  onChange={formik.handleChange}
                >
                  {dayOptions.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Delete Row Button */}
            <Grid item xs={3}>
              <IconButton
                color="error"
                onClick={() => handleRemoveRow(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        {/* Add New Row Button */}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddRow}
          >
            إضافة
          </Button>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            تعديل
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};




export default function ChangePassword() {
  return (
    <div>
      <h2>تغيير 555 المرور</h2>
      {/* <LoginForm /> */}
      <FormExample />
    </div>
  );
}


/*
المادة العلمية التي تدرسها

مدة الحصة

// حدد المواعيد المناسبة
اختر اليوم
حدد الساعة من
حدد الساعة الى


*/