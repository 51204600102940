// components
import BannerWithBg from "./../components/BannerWithBg";
import logo from "./../logo.svg";
import { Grid, Stack, Typography, Box } from "@mui/material";

// assets
import bg_b from "./../assets/bg_b.png";
import bg_4 from "./../assets/bg_4.svg";

function CoursesPage() {
  return (
    <div>

      <BannerWithBg bg={bg_b} text="about" />

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 70px",
          width: "calc(100vw - 140px)",
          backgroundImage: `url(${bg_4})`,
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          {/* 9 3 */}
          <Grid item xs={8}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#0f0",
              }}
            >
               <Stack
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                useFlexGap
                paddingX={2}
                flexWrap="wrap"
              >
                <Box>
                    <Typography variant="h4" style={{ color: "#134847" }}>
                      عن الجمعية
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#134847", textAlign: "center" }}
                    >
                      الجمعية الخيرية لتحفيظ القرآن الكريم بالمدينة المنورة
                      تأسست عام 1410هـ بموجب القرار الوزاري رقم ١١٧
                      وتاريخ ١٤١٠/١١/١٧هـ وتم تسجيلها بوزارة الشؤون الاجتماعية
                      برقم ١٦٤١ وتاريخ ١٤١١/١/١١هـ وتعتبر الجمعية من
                      الجمعيات الخيرية الرائدة في مجال تحفيظ القرآن الكريم
                    </Typography>

                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "80%",
                // backgroundColor: "#fff",
              }}
            >
              
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>



      <div style={{ height: "60vh" }} />

    </div>
  );
}
export default CoursesPage;
