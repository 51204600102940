import CircleIcon from "@mui/icons-material/Circle";
import {
  Typography,
  Stack,
} from "@mui/material";

// 
import SectionHeader from "./SectionHeader";

// assets
import bg_2 from "../assets/bg_2.png";
import PoltgonImg from "../assets/polygon.svg";



function ReviewsSection() {

  return (
    <Stack
      style={{
        backgroundImage: `url(${bg_2})`,
        backgroundSize: "cover",
        backgroundColor: "#f00",
        backgroundPosition: "center",
        width: "100vw",
        color: "white",
        fontSize: "2rem",
        minHeight: "80vh",
      }}
    >
      <Stack
        spacing={2}
        paddingY={4}
        direction="column"
        width="100vw"
        height="100%"
        alignItems="center"
      >
        <SectionHeader headerText="ماذا قالوا عنا ؟" />

        <Stack
          paddingY={6}
          justifyItems="center"
          alignItems="center"
          direction="row"
          alignContent="center"
          spacing={3}
          useFlexGap
          flexWrap="wrap"
        >
          <img
            src={PoltgonImg}
            alt="polygon"
            style={{ width: "300px", height: "300px" }}
          />

          <Stack
            spacing={2}
            direction="column"
            justifyItems="center"
            useFlexGap
            width="80%"
            maxWidth="800px"
          >
            <Typography
              variant="h4"
              style={{ color: "#134847", fontSize: "35px", fontWeight: "600" }}
            >
              {"د / أحمد خالد"}
            </Typography>
            <Typography
              variant="h6"
              style={{ color: "#134847", width: "100%", fontSize: "25px" }}
            >
              {`شيخي الدكتور محمد يتعامل بصورة حسنة، تتميز بالود والتشجيع الدائم. وهو ملتزم بوقت الحلقة وأنا راضٍ تمامًا عن الالتحاق بكم، فالأكاديمية تتميز بالتعامل السلس واللين والحرص علي مصلحة الطلاب كانت تجربة الحفظ مع أسرة "تواقة" جيدة جدًّا جزاكم الله عنا خير الجزاء، وتقبل الله جهدكم وصالح أعمالكم.`}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          spacing={2}
          direction="row"
          justifyItems="center"
          alignItems="center"
          useFlexGap
        >
          <CircleIcon style={{ color: "#f7941d" }} />
          <CircleIcon style={{ color: "#134847" }} />
          <CircleIcon style={{ color: "#134847" }} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ReviewsSection;
