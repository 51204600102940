import { Box, Button, TextField, Typography, Stack } from "@mui/material";
import SectionHeader from "./SectionHeader";
import bg_package from "./../assets/bg_package.svg";

const SubscriptionPlansSection = () => {
  const plans = [
    {
      hours: "6 ساعات",
      sessions: "12 حصة",
      timePerSession: "نصف ساعة",
      totalPrice: "400 جنيها",
      buttonText: "اشترك الآن",
      hasInputFields: false,
    },
    {
      hours: "6 ساعات",
      sessions: "12 حصة",
      timePerSession: "نصف ساعة",
      totalPrice: "400 جنيها",
      buttonText: "اشترك الآن",
      hasInputFields: true,
    },
    {
      hours: "6 ساعات",
      sessions: "6 حصص",
      timePerSession: "ساعة واحدة",
      totalPrice: "300 جنيها",
      buttonText: "اشترك الآن",
      hasInputFields: false,
    },
  ];

  return (
    <Box sx={{ textAlign: "center", py: 5 }}>
      <SectionHeader headerText="باقات الإشتراك" />

      {/* Subscription Cards */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          flexWrap: "wrap",
        }}
      >
        {plans.map((plan, index) => (
          <Box
            key={index}
            sx={{
              width: "320px",
              height: "600px",

              textAlign: "center",
              position: "relative",
              fontFamily: "Arial",
              backgroundImage: `url(${bg_package})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Box
              sx={{
                // put in the center of the card
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                // backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: "10px",
                width: "80%",
                height: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                spacing={1}
                divider={
                  <Box
                    sx={{
                      // border: '0.5px solid #134847',   // make small border between each item
                      border: "0.1em solid #13484720", // make small border between each item

                      width: "100%",
                      // margin: '0 0 5px 0'
                    }}
                  />
                }
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "250px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    إجمالى عدد الساعات
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    {plan.hours}
                  </Typography>
                </Stack>

                <Stack
                  direction={
                    plan.hasInputFields ? "column" : "row"
                    // "row"
                  }
                  spacing={plan.hasInputFields ? 0 : 2}
                  justifyContent="space-between"
                  sx={{ width: "250px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    إجمالى عدد الحصص
                  </Typography>
                  <div>
                    {plan.hasInputFields ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="اختر عدد الحصص"
                        sx={{
                          mb: 4,
                          height: "30px",
                          // width: '100px',
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#134847",
                          fontWeight: "600",
                        }}
                      >
                        {plan.sessions}
                      </Typography>
                    )}
                  </div>
                </Stack>

                <Stack
                  direction={plan.hasInputFields ? "column" : "row"}
                  spacing={plan.hasInputFields ? 0 : 2}
                  justifyContent="space-between"
                  sx={{ width: "250px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    وقت الحصة
                  </Typography>
                  <div>
                    {plan.hasInputFields ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="اختر وقت الحصة"
                        sx={{
                          mb: 4,
                          height: "30px",
                          // width: '100px',
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#134847",
                          fontWeight: "600",
                        }}
                      >
                        {plan.timePerSession}
                      </Typography>
                    )}
                  </div>
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "250px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    القيمة الإجمالية
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#134847",
                      fontWeight: "600",
                    }}
                  >
                    {plan.totalPrice}
                  </Typography>
                </Stack>
              </Stack>

              <Button
                variant="contained"
                onClick={() => alert("تم الاشتراك")}
                sx={{
                  bgcolor: "#f7941d",
                  // paddingTop: '50px',
                  marginTop: "20px",
                  height: "34px",
                  width: "115px",
                  fontSize: "16px",
                  // '&:hover': {
                  //   bgcolor: '#f5731d',
                  // },
                }}
              >
                {plan.buttonText}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SubscriptionPlansSection;
