import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import dataProvider from '../../dataProvider';



// Define the validation schema using Yup
const validationSchema = Yup.object({
  fullName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  gender: Yup.string().oneOf(["M", "F"], 'must match M or F').required('Required'),
  birthDate: Yup.string(),
  // location: Yup.string(),
  audio: Yup.string(),
  video: Yup.string(),
  description: Yup.string(),

});

function FormField({ propId ,placeholder, label, type="text" }: any){
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
    maxWidth: "300px",

  };

  const textFieldStyle = {
    width: "100%",
    maxWidth: "300px",
    // marginBottom: "20px",
  };

  return (
    <Stack>
    <Box component="label" sx={labelStyle} htmlFor={propId}>
      {/* البريد الإلكترونى */}
      {label}
    </Box>
    <Field name={propId}>
      {({ field }: FieldProps) => (
        <TextField
          {...field}
          id={propId}
          variant="outlined"
          type={type}
          placeholder={placeholder}
          sx={textFieldStyle}
        />
      )}
    </Field>
    <ErrorMessage name={propId}>
      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
    </ErrorMessage>
    </Stack>
  )
}


function LoginForm ({defaultValues}: any) {

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",
    maxWidth: "300px",
  };

  function handleSubmit(values: any, { setSubmitting }: any) {
    console.log(values)
    dataProvider.auth.blabla({ 
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      gender: values.gender,
      birthDate: values.birthDate,
      location: values.location,
      audio: values.audio,
      video: values.video,
      description: values.description
     }).then((res) => {
      console.log(res);
      setSubmitting(false);
    }).catch((err) => {
      console.log(err);
      setSubmitting(false);
    })
      
  }
//   {
//     "id": "478ede4d-d5d8-4bc4-5477-08dd1782268c",
//     "fullName": "t0000",
//     "gender": "Male",
//     "email": "tt@admin.com",
//     "phoneNumber": "0147852258",
//     "birthDate": "2024-12-08T11:07:00.863",
//     "creditBalance": 0.00
// }
  // defaultValues
  let initialValues = {
    fullName: '', 
    email: '', 
    phone: '',
    gender: '',
    birthDate: '',
    // location: '',
    audio: '',
    video: '',
    description: '',
  }
  initialValues = {
    ...initialValues,
    ...defaultValues
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Stack spacing={2} direction="row"  alignItems="center" useFlexGap flexWrap="wrap">
            {/* // fullName, email, phone, gender, birthDate, location, audio, video, description, image */}
            <FormField  propId="fullName" placeholder="ادخل الاسم" label="الاسم" />
            <FormField  propId="email" placeholder="ادخل البريد الإلكترونى" label="البريد الإلكترونى" />
            <FormField  propId="phone" placeholder="ادخل رقم الهاتف" label="رقم الهاتف" />
            <FormField  propId="gender" placeholder="ادخل النوع" label="النوع" />
            <FormField  propId="birthDate" placeholder="ادخل العمر" label="العمر" />
            {/* <FormField  propId="location" placeholder="ادخل الموقع" label="الموقع" /> */}

            {/* yt */}
            <FormField  propId="audio" placeholder="https://youtube.com/watch?v=..." label="الصوت" />
            {/* yt */}
            <FormField  propId="video" placeholder="https://youtube.com/watch?v=..." label="الفيديو" />

          </Stack>

            <FormField  propId="description" placeholder="ادخل الوصف" label="الوصف" />


          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            حفظ
          </Button>
          
          
        </Form>
      )}
    </Formik>
  );
};

export default function EditProfile() {
  const [profile, setProfile] = React.useState<any>({});
  React.useEffect(() => {
    (async function () {
      // const resData = await dataProvider.courses.getAll({
      //   limit: 20,
      //   page: 1,
      // });
      // setCourses(resData.data);
      // setCourses(resData.data);
      // -------------------
      const resData2 = await dataProvider.trainer.getOne();
      setProfile(resData2);
      // const resData2 = await dataProvider.trainer.getOne(trainer_id);

    })();
  }, []);
  // name, email, phone, gender, birthDate, location, audio, video, description, image
  return (
    <div>
      <h1>البيانات الشخصية</h1>
      <LoginForm defaultValues={profile} />
    </div>
  );
}