import { Stack, Typography } from "@mui/material";
import BookLogo from "./../assets/book.svg";
import WhiteBookLogo from "./../assets/book_2.svg";


function SectionHeader({ headerText, isWhite = false }: { headerText: string; isWhite?: boolean }) {
  const logo = isWhite ? WhiteBookLogo : BookLogo;
  
  return (
    <Stack spacing={2} direction="row" useFlexGap width="100%" justifyContent="center" alignItems="center" height="100px">
        <img src={logo} alt="book" style={{ height: "20px" }} />
        <Typography variant="h6" component="div" style={{ color: isWhite ? "#FFFFFF" : "#134847", fontSize: "35px" }}>
          {headerText}
        </Typography>
        <img src={logo} alt="book" style={{ height: "20px" }} />
    </Stack>
  );
}

export default SectionHeader;