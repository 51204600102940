import { Box, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

// assets
import bg_a from "./../assets/bg_a.png";

// components
import SectionHeader from "./SectionHeader";

function BannerWithBg({
  text,
  bg = bg_a,
  navLinks = [],
}: {
  text: string;
  bg?: any;
  navLinks?: { to: string; text: string }[];
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        color: "white",
        fontSize: "2rem",
        minHeight: "max(20vh, 281px)",
        maxHeight: "20vh",
        overflow: "hidden",
      }}
    >
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        bgcolor="rgba(0,0,0,0.5)"
        minHeight="inherit"
      >
        <SectionHeader headerText={text} isWhite />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          useFlexGap
          divider={<Box paddingX={1}>-</Box>}
        >
          {navLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.to}
              style={({ isActive }) => ({
                color: isActive ? "#ffb56e" : "#fff",
                fontSize: "20px",
              })}
            >
              <Typography
                variant="h6"
                component="div"
                style={{ color: "inhert", fontSize: "20px" }}
              >
                {link.text}
              </Typography>
            </NavLink>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default BannerWithBg;