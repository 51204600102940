import { Chip, Stack, Typography } from "@mui/material";

function PriceChip({ price, text }: { price: string; text: string }) {
    return (
      <Chip
        label={
          <Stack spacing={2} direction="row" useFlexGap>
            <Typography
              component="div"
              style={{ color: "#f7941d", fontSize: "20px", fontWeight: "bold" }}
            >
              {text}
            </Typography>
  
            <Typography
              component="div"
              style={{ color: "#134847", fontSize: "20px" }}
            >
              {price}
            </Typography>
          </Stack>
        }
        variant="outlined"
        color="primary"
        style={{
          fontSize: "20px",
          borderRadius: "4px",
          border: "none",
          boxShadow: "0px 3px 6px #00000029",
          height: "40px",
          padding: "3px 20px",
        }}
      />
    );
}

export default PriceChip;