import { RouteObject } from "react-router-dom";
import MainLayout from "./layout/MainLayout";

// home
import HomePage from "./pages/HomePage";

// auth
import LoginPage from "./pages/LoginPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RegisterPage from "./pages/RegisterPage";

// other
import CoursesPage from "./pages/CoursesPage";
import ArticlesPage from "./pages/ArticlesPage";
import TeachersPage from "./pages/TeachersPage";
import PackagesPage from "./pages/PackagesPage";
import AboutPage from "./pages/AboutPage";
import RegisterAsTeacherPage from "./pages/RegisterAsTeacherPage";


import CourseInfoPage from "./pages/CourseInfoPage";

// trainer profile
import TrainerProfilePage from "./pages/TrainerProfilePage/index";
import TrainerEditProfilePage from "./pages/TrainerProfilePage/EditProfile";
import TrainerChangePasswordPage from "./pages/TrainerProfilePage/ChangePassword";
import TrainerProfileOverviewPage from "./pages/TrainerProfilePage/ProfileOverview";
import TrainerEditAvailableTimePage from "./pages/TrainerProfilePage/EditAvailableTime";


// errors
import PageNotFound from "./pages/PageNotFound";

const MainRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        // ErrorBoundary: ({ error }) => <div>Error: {error.message}</div>,
      },
      // login
      {
        path: "/auth/login",
        element: <LoginPage />,
      },
      // forget password
      {
        path: "/auth/forget-password",
        element: <ForgetPasswordPage />,
      },
      // reset password
      {
        path: "/auth/reset-password",
        element: <ResetPasswordPage />,
      },
      // register
      {
        path: "/auth/register",
        element: <RegisterPage />,
      },
      // Activation Code
      // new password
      // CoursesPage
      {
        path: "/courses",
        element: <CoursesPage />,
      },
      // articles
      {
        path: "/articles",
        element: <ArticlesPage />,
      },
      // teachers
      {
        path: "/teachers",
        element: <TeachersPage />,
      },
      // packages
      {
        path: "/packages",
        element: <PackagesPage />,
      },
      // about
      {
        path: "/about",
        element: <AboutPage />,
      },
      // register as teacher
      {
        path: "/register-as-teacher",
        element: <RegisterAsTeacherPage />
      },
      // --------------------------------------------
      {
        path: "/courses/:id",
        element: <CourseInfoPage />,
      },
      {
        path: "/trainer-profile",
        element: <TrainerProfilePage />,
        children: [
          { path: "", element: <TrainerProfileOverviewPage /> }, // Matches "/trainer-profile"
          { path: "edit-available-time", element: <TrainerEditAvailableTimePage /> }, // Matches "/trainer-profile"
          { path: "change-password", element: <TrainerChangePasswordPage /> }, // Matches "/trainer-profile/changepassword"
          { path: "edit", element: <TrainerEditProfilePage /> }, // Matches "/trainer-profile/edit"
        ],
      },
      // {
      //   path: "trainer-profile/courses",
      //   element: <TrainerProfilePage />,
      // },
      

      // 404
      {
        path: "*",
        element: <PageNotFound />,
      },
    ]
  }
]


export default MainRoutes;
