import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

// components
import SectionHeader from "./SectionHeader";
import ArticleCard from "./ArticleCard";
import dataProvider from "../dataProvider";
import Blog from "./../types/Blog";


const BlogSection = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  useEffect(() => {
    (async () => {
      const data = await dataProvider.blogs.getAll();
      setBlogs(data.data);
    })();
  }, []);



  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? blogs.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === blogs.length - 3 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      paddingBottom={8}
      sx={{
        mt: 4,
      }}
    >
      <SectionHeader headerText="مقالات و معلومات" />

      <Box
        paddingY={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Button
          onClick={handlePrev}
          color="warning"
          sx={{ position: "absolute", left: "10%", zIndex: 1 }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>
        {blogs?(<Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          paddingY={2}
          useFlexGap
        >


        {blogs.slice(index, index + 3).map((blog, i) => (
          <ArticleCard key={i} data={blog} />
        ))}
        </Stack>):null}

        <Button
          onClick={handleNext}
          color="warning"
          sx={{ position: "absolute", right: "10%", zIndex: 1 }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        paddingY={2}
      >
        <Button
          variant="contained"
          component={Link}
          to="/articles"
          color="warning"
          sx={{ mt: 4, width: "170px", height: "50px" }}
        >
          View More
        </Button>
      </Stack>
    </Box>
  );
};

export default BlogSection;
