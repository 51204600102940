import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
} from "@mui/material";

// components
import SectionHeader from "./SectionHeader";

// assets
import bg_3 from "../assets/bg_3.png";

// types
import Question from "../types/Question";

// data
import questions from "../data/qa";



function QASection() {
  return (
    <div
      style={{
        backgroundImage: `url(${bg_3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontSize: "2rem",
        minHeight: "80vh",
      }}
    >
      <Stack
        spacing={2}
        paddingY={4}
        direction="column"
        style={{ width: "100vw" }}
        justifyItems="center"
      >
        <SectionHeader headerText="الأسئلة الشائعة" />

        <Stack
          style={{ width: "100vw" }}
          justifyItems="center"
          alignItems="center"
        >
          <Stack
            spacing={2}
            direction="column"
            justifyItems="center"
            style={{ width: "80%", maxWidth: "800px" }}
          >
            {questions.map((q: Question, i: number) => (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={"panel1-content" + i}
                  id={"panel1-header" + i}
                >
                  <Typography style={{ fontSize: "20px", fontWeight: "550" }}>
                    {q.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontSize: "20px", fontWeight: "550" }}>
                    {q.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default QASection;
