// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";
import SubscriptionPlansSection from "../components/SubscriptionPlansSection";

// assets
import bg_b from "./../assets/bg_b.png";

function CoursesPage() {
  return (
    <div>
      <BannerWithBg bg={bg_b} text="Packages" />
      <SubscriptionPlansSection />
    </div>
  );
}
export default CoursesPage;
