import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';


// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";

// assets
import bg_4 from "./../assets/bg_4.svg";
import bg_a from "./../assets/bg_a.png";



// Define the validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string(),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
  phone: Yup.string(),
  area: Yup.string(),
  gender: Yup.string().oneOf(["M", "F", "NA"], ""),
  age: Yup.string(),
});


const LoginForm: React.FC = () => {
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
  };

  const textFieldStyle = {
    width: "100%",
    // marginBottom: "20px",
  };

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",

  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '', 
        password: '',
        confirmPassword: '',
        phone: '',
        area: '',
        gender: '',
        age: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            
            // use 2 columns layout
          //  display: "flex",
          //   flexDirection: "column",

            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px 60px",
            justifyContent: "center",





          }}
        >
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >

          <Box component="label" sx={labelStyle} htmlFor="name">
            الاسم
          </Box>
          <Field name="name">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="name"
                variant="outlined"
                placeholder="ادخل الاسم"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="name">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >


          <Box component="label" sx={labelStyle} htmlFor="email">
            البريد الإلكترونى
          </Box>
          <Field name="email">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="email"
                variant="outlined"
                placeholder="ادخل البريد الإلكترونى"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="email">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="password">
            كلمة المرور
          </Box>
          <Field name="password">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="password"
                type="password"
                variant="outlined"
                placeholder="ادخل كلمة المرور"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="password">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="confirmPassword">
            تأكيد كلمة المرور
          </Box>
          <Field name="confirmPassword">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="confirmPassword"
                type="password"
                variant="outlined"
                placeholder="ادخل تأكيد كلمة المرور"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="confirmPassword">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="phone">
            رقم الهاتف
          </Box>
          <Field name="phone">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="phone"
                variant="outlined"
                placeholder="ادخل رقم الهاتف"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="phone">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="area">
            المنطقة
          </Box>
          <Field name="area">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="area"
                variant="outlined"
                placeholder="ادخل المنطقة"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="area">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="gender">
            الجنس
          </Box>
          <Field name="gender">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="gender"
                variant="outlined"
                placeholder="ادخل الجنس"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="gender">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
          <div style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
          <Box component="label" sx={labelStyle} htmlFor="age">
            العمر
          </Box>
          <Field name="age">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="age"
                variant="outlined"
                placeholder="ادخل العمر"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="age">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
          </div>
         
          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            Register
          </Button>
        </Form>
      )}
    </Formik>
  );
};


function LoginPage() {
  const navLinks = [
    { to: "/", text: "الرئيسية" },
    { to: "/auth/login", text: "تسجيل الدخول" },
    { to: "/auth/forget-password", text: "فقد كلمة المرور" },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${bg_4})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
  };

  const formContainerStyle = {
    minHeight: "750px",
    width: "65vw",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #4FBCC0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const formStyle = {
    width: "50%",
    minHeight: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };


  return (
    <div>
      <BannerWithBg text="إنشاء حساب جديد" bg={bg_a} navLinks={navLinks} />

      <Stack alignItems="center" justifyContent="center" height="658px" sx={backgroundStyle}>
        <Box sx={formContainerStyle}>
          <Box sx={formStyle}>
            <LoginForm />
          </Box>
        </Box>
      </Stack>

    </div>
  );
}


export default LoginPage;
