import { Outlet } from 'react-router-dom';

// components
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function MainLayout() {
    return (
        <div style={{ width: "100vw", overflowX: "hidden" }}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}
