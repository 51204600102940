import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';


// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import BannerWithBg from "../components/BannerWithBg";

// assets
import bg_4 from "./../assets/bg_4.svg";
import bg_a from "./../assets/bg_a.png";
import dataProvider from '../dataProvider';



// Define the validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
});


function LoginForm({setStep}: any) {
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
  };

  const textFieldStyle = {
    width: "100%",
    // marginBottom: "20px",
  };

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",

  };

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
        dataProvider.auth.forgetPassword(values).then((res) => {
          console.log(res);
          setSubmitting(false);
          setStep(2);
        }).catch((err) => {
          console.log(err);
          setSubmitting(false);
        })
      }}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Box component="label" sx={labelStyle} htmlFor="email">
            البريد الإلكترونى
          </Box>
          <Field name="email">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="email"
                variant="outlined"
                placeholder="ادخل البريد الإلكترونى"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="email">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>

          

          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            تاكيد
          </Button>
        </Form>
      )}
    </Formik>
  );
};


function ForgetPasswordPage() {
  const [step, setStep] = React.useState(1);
  const navLinks = [
    { to: "/", text: "الرئيسية" },
    { to: "/auth/login", text: "تسجيل الدخول" },
    { to: "/auth/forget-password", text: "فقد كلمة المرور" },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${bg_4})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const formContainerStyle = {
    minHeight: "450px",
    width: "65vw",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #4FBCC0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const formStyle = {
    width: "50%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };


  return (
    <div>
      <BannerWithBg text="فقد كلمة المرور" bg={bg_a} navLinks={navLinks} />

      <Stack alignItems="center" justifyContent="center" height="658px" sx={backgroundStyle}>
        <Box sx={formContainerStyle}>
          <Box sx={formStyle}>
            {
              step === 2 ? (
                <div>تم ارسال رابط تغيير كلمة المرور الى بريدك الإلكترونى</div>
              ) : (
                <LoginForm setStep={setStep} />
              )
            }
          </Box>
        </Box>
      </Stack>

    </div>
  );
}


export default ForgetPasswordPage;
