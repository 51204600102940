import logo from "../logo.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Box,
  Typography,
  IconButton,
  Grid,
  useMediaQuery,
} from "@mui/material";

// assets
import langLogo from "./../assets/lang.svg";

function Header() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTrainer = localStorage.getItem("role") === "Trainer";
  const isLogged = localStorage.getItem("token");

  const navLinksPublic: { title: string; path: string }[] = [
    { title: "الرئيسية", path: "/" },
    { title: "الكورسات", path: "/courses" },
    { title: "المقالات", path: "/articles" },
    { title: "المعلمون", path: "/teachers" },
    { title: "الباقات", path: "/packages" },
    { title: "من نحن", path: "/about" },
    { title: "طلب تسجيل كمعلم", path: "/register-as-teacher" },
  ];

  const navLinksForTrainer: { title: string; path: string }[] = [
    { title: "الرئيسية", path: "/" },
    { title: "المقالات", path: "/articles" },
    { title: "من نحن", path: "/about" },
  ];
  const navLinks = isTrainer ? navLinksForTrainer : navLinksPublic;

  return (
    <header>
      <Grid
        container
        boxShadow={3}
        alignContent={"center"}
        justifyContent="center"
        bgcolor={"#ffffff"}
        height={120}
        width="100vw"
        spacing={2}
        direction="row"
        alignItems="center"
      >
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "center" }} height="75px">
            <Link to="/" style={{ textDecoration: "none", width: "75px" }}>
              <Box
                component="img"
                src={logo}
                style={{ width: "75px", height: "75px" }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={7}>
          {!isMobile && (
            <Stack
              spacing={2}
              justifyContent="center"
              useFlexGap
              direction="row"
            >
              {navLinks.map((value) => (
                <Link to={value.path} key={value.title}>
                  <Typography
                    component="div"
                    style={{
                      fontSize: "20px",
                      fontWeight: "550",
                      color: "#111111",
                    }}
                  >
                    {value.title}
                  </Typography>
                </Link>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item xs={3}>
          <Stack
            spacing={2}
            useFlexGap
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton aria-label="langLogo" size="large">
              <img src={langLogo} alt="langLogo" />
            </IconButton>

            {!isMobile && (
              <Button
                // component={Link}
                // to="/auth/login"
                // to={isLogged ? "/auth/logout" : "/auth/login"}
                onClick={() => {
                  if (isLogged) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    navigate("/");
                  } else {
                    navigate("/auth/login");
                  }
                }}
                variant="contained"
                sx={{
                  bgcolor: "#f7941d",
                  borderRadius: "4px",
                  padding: "10px 40px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  height: "50px",
                }}
              >
                {
                  isLogged? "تسجيل الخروج" : " تسجيل الدخول "
                  }
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </header>
  );
}

export default Header;
