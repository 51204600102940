const config = {
  // API_HOST: "http://localhost:3000/api",
  // API_HOST: "http://localhost:4000",
  API_HOST: "http://localhost:4000",

  COLORS: {
    PRIMARY: "#007bff",
    SECONDARY: "#6c757d",
    SUCCESS: "#28a745",
    DANGER: "#dc3545",
    WARNING: "#ffc107",
    INFO: "#17a2b8",
    LIGHT: "#f8f9fa",
    DARK: "#343a40",
    WHITE: "#fff",
    // OTHER

    
  },
};

export const API_HOST = 'https://hafezplatform-fgb9b6fggpc5ctc7.canadacentral-01.azurewebsites.net/api';
// export const API_HOST = config.API_HOST;
export default config;
