import { Box, Grid, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import PriceChip from "../components/PriceChip";

// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";
import CourseCard from "../components/CourseCard";

// assets
import bg_b from "./../assets/bg_b.png";
import bg_4 from "./../assets/bg_4.svg";


import dataProvider from "../dataProvider";
import Course from "../types/Course";
import {
  Card,
  CardContent,
  CardMedia,

} from "@mui/material";

function CourseInfoCard({ data }: { data: any }) {
  return (
    <Card
      sx={{
        boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.2)",
        borderRadius: "10px",
        width: "300px",
        height: "580px",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={data.img}
        alt={data.title}
      />
      <CardContent 
        style={{
          height: "335px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={1.5}
          direction="column"
          justifyContent={"space-between"}
          // sx={{ height: "100%" }}
          divider={<Box sx={{ border: "1px solid #e0e0e0" }} />}
        >
          <Typography component="div"
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#134847",
          }}
          >
            {data.title}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography style={{ fontSize: "20px", color: "#134847" }}>
              الحد الادني للساعات
            </Typography>
            <Typography style={{ fontSize: "20px", color: "#f7941d" }}>
              {data.hours}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography style={{ fontSize: "20px", color: "#134847" }}>
              السعر للساعة
            </Typography>

            <Typography style={{ fontSize: "20px", color: "#f7941d" }}>
              {data.pricePerHour}
            </Typography>
          </Box>

          <Typography sx={{ mt: 1, 
            fontSize: "18px", 
            color: "#134847", 
            textAlign: "start",
            // max 3 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            
            
            }}>
            {data.description}
          </Typography>

        </Stack>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="contained" style={{ backgroundColor: "#f7941d", color: "#fff", fontSize: "20px", 
            width: "120px"

            }}>
              تفاصيل
            </Button>
            <Button variant="contained" style={{ backgroundColor: "#fff5d7", color: "#134847", fontSize: "20px",
            width: "120px"

             }}>
              {data.totalPrice}
            </Button>
          </Box>
      </CardContent>
    </Card>
  );
}


const filterOptions = {
  allSubjects: [
    { label: "قرآن كريم", value: "Quran" },
    { label: "علوم العربية", value: "Arabic" },
    { label: "قواعد التجويد", value: "Tajweed" },
    { label: "شرح صحيح مسلم", value: "SahihMuslim" },
  ],
  allTeachers: [
    { label: "الشيخ عبد الرحمن", value: "SheikhAbdulRahman" },
    { label: "دكتور خالد محمود", value: "DrKhaledMahmoud" },
    { label: "الشيخ مصطفى عمر", value: "SheikhMustafaOmar" },
    { label: "الشيخ محمود السيد", value: "SheikhMahmoudElSayed" },
    { label: "دكتور خالد الهندي", value: "DrKhaledAlHindi" },
    { label: "الشيخ مراد حسين", value: "SheikhMoradHussein" },
  ],
  allSections: [
    { label: "قسم الرجال", value: "Men" },
    { label: "قسم النساء", value: "Women" },
  ],
};

interface FilterValues {
  subjects: string[];
  teachers: string[];
  sections: string[];
}

interface FilterFormProps {
  onFilterChange: (values: FilterValues) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({ onFilterChange }) => {
  return (
    <Formik<FilterValues>
      initialValues={{
        subjects: [],
        teachers: [],
        sections: [],
      }}
      onSubmit={(values) => {
        onFilterChange(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            display: "flex",
            width: "80%",
            flexDirection: "column",
          }}
        >
          {/* Subjects */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المادة</Typography>
            <FormGroup>
              {filterOptions.allSubjects.map((subject) => (
                <FormControlLabel
                  key={subject.value}
                  control={
                    <Checkbox
                      checked={values.subjects.includes(subject.value)}
                      onChange={() =>
                        setFieldValue(
                          "subjects",
                          toggleValue(values.subjects, subject.value)
                        )
                      }
                    />
                  }
                  label={subject.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Teachers */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المعلم</Typography>
            <FormGroup>
              {filterOptions.allTeachers.map((teacher) => (
                <FormControlLabel
                  key={teacher.value}
                  control={
                    <Checkbox
                      checked={values.teachers.includes(teacher.value)}
                      onChange={() =>
                        setFieldValue(
                          "teachers",
                          toggleValue(values.teachers, teacher.value)
                        )
                      }
                    />
                  }
                  label={teacher.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Sections */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">القسم</Typography>
            <FormGroup>
              {filterOptions.allSections.map((section) => (
                <FormControlLabel
                  key={section.value}
                  control={
                    <Checkbox
                      checked={values.sections.includes(section.value)}
                      onChange={() =>
                        setFieldValue(
                          "sections",
                          toggleValue(values.sections, section.value)
                        )
                      }
                    />
                  }
                  label={section.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Apply Filters
          </Button>
        </Form>
      )}
    </Formik>
  );
};

// Utility function to toggle values in an array
const toggleValue = (array: string[], value: string): string[] => {
  return array.includes(value)
    ? array.filter((item) => item !== value)
    : [...array, value];
};

function CoursesPage() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const bestCourseData = {
    title: "تثبيت القرأن الكريم",
    tags: [
      { price: "100 جنيها", text: "سعر الساعة الواحدة" },
      { price: "400 جنيها", text: "السعر الكلى" },
      { price: "4 ساعات", text: "الحد الادني لعدد ساعات الكورس (خلال الشهر)" },
    ],
    describtion:
      "نقدم لكِ برنامج متخصصً في تعلم وحفظ القرآن الكريم للبنين ، حيث سيحظى ولدك بتجربة تعليمية فريدة ومتميزة. ما يميز البرنامج: - مرونة في المواعيد: تناسب جميع أوقات ولدك. - معلمون متخصصون: جميع المعلمين حاصلين على إجازات بأسانيد متصلة للنبي صلى الله عليه وسلم في تعليم القرآن الكريم وتجويده. - متابعة فردية: تقرير عن كل حلقة باستمارات المتابعة ودرجات تقييم المعلم لحفظ واتقان الطالب . - أجواء إيمانية: تساعد الطفل على الاستمتاع بتلاوة وحفظ القرآن الكريم. - تفسير مبسط: تفسير مبسط لمعاني الآيات ليساعد الطفل على الفهم والحفظ بسهولة. - حلقات خاصة فردية: الحلقة فردية وكل طالب له معلمه الخاص. - شهادات تهنئة تشجيعية: بعد انتهاء حفظ جزء من القرآن الكريم يمنح الطفل شهادة تهنئة تشجيعية. لا تتردد في التواصل معنا لمعرفة المزيد وتسجيلك في الكورس.",
  };


  const [course, setCourse] = React.useState<Course | null>(null);
  React.useEffect(() => {
    (async function () {
      const resData = await dataProvider.courses.getAll({
        limit: 1,
        page: 1,
      });
      setCourse(resData.data[0]);
    })();
  }, []);

  return (
    <div style={{ width: "100vw" }}>

      <BannerWithBg bg={bg_b} text="الكورسات" />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 70px",
          width: "calc(100vw - 140px)",
          backgroundImage: `url(${bg_4})`,
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          {/* 9 3 */}
          <Grid item xs={9}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#0f0",
              }}
            >
               {/* <Stack
                spacing={5}
                direction="row"
                justifyContent="center"
                alignItems="center"
                useFlexGap
                paddingX={2}
                flexWrap="wrap"
              > */}

                  {/* {course && <CourseInfoCard data={course} />} */}
                  {
                    course && 
                      <Stack sx={{ 
                      
                        flexDirection: "column",
                      display: "flex", justifyContent: "center" , width: "95%"}}>
                        <img src={course.img} alt="course" style={{ 
                          width: "100%", 
                          borderRadius: "10px",
                          boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.2)",
                          maxHeight: '30vh',
                          objectFit: 'cover',
                           }} />

          <Stack
            spacing={2}
            useFlexGap
            alignItems="space-between"
            paddingX={2}
            paddingY={5}
            width="100%"
          >
            <Typography
              variant="h6"
              component="div"
              style={{ color: "#f7941d", fontSize: "35px", fontWeight: "bold" }}
            >
              {bestCourseData.title}
            </Typography>
            <Stack spacing={1.6} direction="row" useFlexGap flexWrap="wrap">
              {bestCourseData.tags.map((tag) => (
                <PriceChip price={tag.price} text={tag.text} key={tag.text} />
              ))}
            </Stack>

            <Typography
              variant="h6"
              component="div"
              style={{
                color: "#134847",
                fontSize: "25px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "20",
                WebkitBoxOrient: "vertical",
                textAlign: "justify",
              }}
            >
              {bestCourseData.describtion}
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#f7941d",
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                width: "200px",
                height: "50px",
                borderRadius: "4px",
              }}
            >
              {"إشترك الأن"}
            </Button>
          </Stack>


                        


                      </Stack>
                  }
              {/* </Stack> */}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#fff",
              }}
            >
              {/* <h1>الفلاتر</h1> */}
              <FilterForm
                onFilterChange={(values) =>
                  alert(JSON.stringify(values, null, 2))
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

    </div>
  );
}
export default CoursesPage;
