// <BannerWithBg bg={bg_b} text="teachers" />
import { Box, Grid, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
} from "@mui/material";

// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";
// import CourseCard from "../components/CourseCard";
// import ArticleCard from "../components/ArticleCard";
import TeacherCard from "../components/TeacherCard";


// assets
import bg_b from "./../assets/bg_b.png";
import bg_4 from "./../assets/bg_4.svg";

import dataProvider from "../dataProvider";
import { useEffect, useState } from "react";



type Teacher = {
  name: string;
  title: string;
  description: string;
  img: string;
}



function TeachersPage() {
  const [teachers, setTeachers] = useState<Teacher[] | []>([]);

  useEffect(() => {
    (async () => {
      const data = await dataProvider.trainer.getAll({
        limit: 30,
      });
      setTeachers(data.data);
    })();
  }, []);
  
  return (
    <div style={{ width: "100vw" }}>

      <BannerWithBg bg={bg_b} text="teachers" />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 70px",
          width: "calc(100vw - 140px)",
          backgroundImage: `url(${bg_4})`,
        }}
      >
   
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#0f0",
              }}
            >
              <Stack
                spacing={5}
                direction="row"
                justifyContent="center"
                alignItems="center"
                useFlexGap
                paddingX={2}
                flexWrap="wrap"
              >
                {teachers.map((teacher, index) => (
                  <TeacherCard data={teacher} key={index} />
                ))}
              </Stack>
            </Box>
         
      </Box>

    </div>
  );
}
export default TeachersPage;
