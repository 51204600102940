import { Box, Grid, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
} from "@mui/material";

// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";
import CourseCard from "../components/CourseCard";

// assets
import bg_b from "./../assets/bg_b.png";
import bg_4 from "./../assets/bg_4.svg";


import dataProvider from "../dataProvider";
import Course from "../types/Course";


const filterOptions = {
  allSubjects: [
    { label: "قرآن كريم", value: "Quran" },
    { label: "علوم العربية", value: "Arabic" },
    { label: "قواعد التجويد", value: "Tajweed" },
    { label: "شرح صحيح مسلم", value: "SahihMuslim" },
  ],
  allTeachers: [
    { label: "الشيخ عبد الرحمن", value: "SheikhAbdulRahman" },
    { label: "دكتور خالد محمود", value: "DrKhaledMahmoud" },
    { label: "الشيخ مصطفى عمر", value: "SheikhMustafaOmar" },
    { label: "الشيخ محمود السيد", value: "SheikhMahmoudElSayed" },
    { label: "دكتور خالد الهندي", value: "DrKhaledAlHindi" },
    { label: "الشيخ مراد حسين", value: "SheikhMoradHussein" },
  ],
  allSections: [
    { label: "قسم الرجال", value: "Men" },
    { label: "قسم النساء", value: "Women" },
  ],
};

interface FilterValues {
  subjects: string[];
  teachers: string[];
  sections: string[];
}

interface FilterFormProps {
  onFilterChange: (values: FilterValues) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({ onFilterChange }) => {
  return (
    <Formik<FilterValues>
      initialValues={{
        subjects: [],
        teachers: [],
        sections: [],
      }}
      onSubmit={(values) => {
        onFilterChange(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            display: "flex",
            width: "80%",
            flexDirection: "column",
          }}
        >
          {/* Subjects */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المادة</Typography>
            <FormGroup>
              {filterOptions.allSubjects.map((subject) => (
                <FormControlLabel
                  key={subject.value}
                  control={
                    <Checkbox
                      checked={values.subjects.includes(subject.value)}
                      onChange={() =>
                        setFieldValue(
                          "subjects",
                          toggleValue(values.subjects, subject.value)
                        )
                      }
                    />
                  }
                  label={subject.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Teachers */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المعلم</Typography>
            <FormGroup>
              {filterOptions.allTeachers.map((teacher) => (
                <FormControlLabel
                  key={teacher.value}
                  control={
                    <Checkbox
                      checked={values.teachers.includes(teacher.value)}
                      onChange={() =>
                        setFieldValue(
                          "teachers",
                          toggleValue(values.teachers, teacher.value)
                        )
                      }
                    />
                  }
                  label={teacher.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Sections */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">القسم</Typography>
            <FormGroup>
              {filterOptions.allSections.map((section) => (
                <FormControlLabel
                  key={section.value}
                  control={
                    <Checkbox
                      checked={values.sections.includes(section.value)}
                      onChange={() =>
                        setFieldValue(
                          "sections",
                          toggleValue(values.sections, section.value)
                        )
                      }
                    />
                  }
                  label={section.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Apply Filters
          </Button>
        </Form>
      )}
    </Formik>
  );
};

// Utility function to toggle values in an array
const toggleValue = (array: string[], value: string): string[] => {
  return array.includes(value)
    ? array.filter((item) => item !== value)
    : [...array, value];
};

function CoursesPage() {
  const [courses, setCourses] = React.useState<Course[]>([]);
  React.useEffect(() => {
    (async function () {
      const resData = await dataProvider.courses.getAll({
        limit: 20,
        page: 1,
      });
      setCourses(resData.data);
    })();
  }, []);

  return (
    <div style={{ width: "100vw" }}>

      <BannerWithBg bg={bg_b} text="الكورسات" />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 70px",
          width: "calc(100vw - 140px)",
          backgroundImage: `url(${bg_4})`,
        }}
      >
        <Grid container spacing={2} direction="row" justifyContent="center">
          {/* 9 3 */}
          <Grid item xs={9}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#0f0",
              }}
            >
               <Stack
                spacing={5}
                direction="row"
                justifyContent="center"
                alignItems="center"
                useFlexGap
                paddingX={2}
                flexWrap="wrap"
              >
                {[...courses, ...courses].map((course, index) => (
                  <CourseCard data={course} key={index} />
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#fff",
              }}
            >
              {/* <h1>الفلاتر</h1> */}
              <FilterForm
                onFilterChange={(values) =>
                  alert(JSON.stringify(values, null, 2))
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

    </div>
  );
}
export default CoursesPage;
