export default {
    email: "info@hafez.sa",
    phone: "920033210",
    Phone2: "920033210",
    address: "المملكة العربية السعودية - الرياض",
    socialLinks: [
        { name: "twitter", url: "https://twitter.com" },
        { name: "facebook", url: "https://facebook.com" },
        { name: "instagram", url: "https://instagram.com" },
        { name: "youtube", url: "https://youtube.com" },
    ],
};