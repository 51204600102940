import {
  Stack,
  Box,
  Typography,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";

// components
import SectionHeader from "./SectionHeader";
import PriceChip from "./PriceChip";

// assets
import BestCourseImg from "./../assets/best_course.png";


function BestCourseSection() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const bestCourseData = {
    title: "تثبيت القرأن الكريم",
    tags: [
      { price: "100 جنيها", text: "سعر الساعة الواحدة" },
      { price: "400 جنيها", text: "السعر الكلى" },
      { price: "4 ساعات", text: "الحد الادني لعدد ساعات الكورس (خلال الشهر)" },
    ],
    describtion:
      "نقدم لكِ برنامج متخصصً في تعلم وحفظ القرآن الكريم للبنين ، حيث سيحظى ولدك بتجربة تعليمية فريدة ومتميزة. ما يميز البرنامج: - مرونة في المواعيد: تناسب جميع أوقات ولدك. - معلمون متخصصون: جميع المعلمين حاصلين على إجازات بأسانيد متصلة للنبي صلى الله عليه وسلم في تعليم القرآن الكريم وتجويده. - متابعة فردية: تقرير عن كل حلقة باستمارات المتابعة ودرجات تقييم المعلم لحفظ واتقان الطالب . - أجواء إيمانية: تساعد الطفل على الاستمتاع بتلاوة وحفظ القرآن الكريم. - تفسير مبسط: تفسير مبسط لمعاني الآيات ليساعد الطفل على الفهم والحفظ بسهولة. - حلقات خاصة فردية: الحلقة فردية وكل طالب له معلمه الخاص. - شهادات تهنئة تشجيعية: بعد انتهاء حفظ جزء من القرآن الكريم يمنح الطفل شهادة تهنئة تشجيعية. لا تتردد في التواصل معنا لمعرفة المزيد وتسجيلك في الكورس.",
  };

  return (
    <Box>
      <SectionHeader headerText="أفضل الكورسات" />
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={isMobile?12:4}>
          <img
            src={BestCourseImg}
            // src={"https://placeholder.co/642x487/png"}
            alt="best course"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={isMobile?12:6}>
          <Stack
            spacing={2}
            useFlexGap
            alignItems="space-between"
            paddingX={2}
            paddingY={5}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ color: "#f7941d", fontSize: "35px", fontWeight: "bold" }}
            >
              {bestCourseData.title}
            </Typography>
            <Stack spacing={1.6} direction="row" useFlexGap flexWrap="wrap">
              {bestCourseData.tags.map((tag) => (
                <PriceChip price={tag.price} text={tag.text} key={tag.text} />
              ))}
            </Stack>

            <Typography
              variant="h6"
              component="div"
              style={{
                color: "#134847",
                fontSize: "25px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {bestCourseData.describtion}
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#f7941d",
                color: "#fff",
                fontSize: "20px",
                marginTop: "20px",
                width: "200px",
                height: "50px",
                borderRadius: "4px",
              }}
            >
              {"إشترك الأن"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BestCourseSection;
