import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

import SectionHeader from "./SectionHeader";
import CourseCard from "./CourseCard";

import backgroundImage from "./../assets/bg_1.png";
import dataProvider from "../dataProvider";
import Course from "../types/Course";


const CoursesSection = () => {
  const [courses, setCourses] = useState<Course[] | []>([]);
  useEffect(() => {
    (async () => {
      const data = await dataProvider.courses.getAll();
      // setCourses(data.data);
      setCourses(data.data);
    })();
  }, []);


  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? courses.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === courses.length - 3 ? 0 : prevIndex + 1
    );
  };
  if (courses.length === 0) return null;

  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 4,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        py: 4,
        width: "100vw",
      }}
    >
      <SectionHeader headerText="الكورسات التعليمية" />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Button
          onClick={handlePrev}
          sx={{ position: "absolute", left: "10%", zIndex: 1, color: "orange" }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>

        <Grid container spacing={2} justifyContent="center">
          {courses.slice(index, index + 3).map((course, i) => (
            <Grid item key={i}>
              <CourseCard data={course} />
            </Grid>
          ))}
        </Grid>

        <Button
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: "10%",
            zIndex: 1,
            color: "orange",
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Box>

      <Button
        variant="contained"
        // color="success"
        sx={{
          mt: 4,
          backgroundColor: "#fff",
          width: "170px",
          height: "50px",
          color: "#134847",
          fontSize: "20px",
          fontWeight: "bold",
        }}
        component={Link}
        to="/courses"
      >
        عرض المزيد
      </Button>
    </Box>
  );
};

export default CoursesSection;
