import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
// import MicIcon from "@mui/icons-material/Mic";
// import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import MicIcon from "../assets/microphone_2.svg";
import PlayCircleIcon from "../assets/video.svg";

function TeacherCard({ data }: { data: any }) {
  return (
    <Card
      sx={{
        height: "420px",
        width: "280px",
        padding: 2,
        textAlign: "center",
        borderRadius: "15px",
        backgroundColor: "#fff2dc",
        // fff2dc00
        // fdfbf7
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        overflow: "visible",
      }}
    >
      <Box sx={{ 
        height: "120px",
        display: "flex", justifyContent: "center", mb: 2 }}>
        <Box
          sx={{
            width: 100,
            height: 100,
            overflow: "hidden",
            borderRadius: "50%",
            border: "4px solid #ffa726",
            mt: "10px",

          }}
        >
          <CardMedia
            component="img"
            height="100%"
            image={data.img}
            alt={data.name}
          />
        </Box>
      </Box>
      <CardContent
      style={{
        position: "relative",
        height: "250px",
        // show overflow ellipsis
        // overflow: "visible",
      }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", fontSize: "24px",}}>
          {data.name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: "18px", mb: 1 }}>
          {data.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ 
          fontSize: "18px",
          overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "5",
            WebkitBoxOrient: "vertical",
            textAlign: "justify",
            mb: 2 
          }}>
          {data.description}
        </Typography>
        <Box sx={{ 
          position: "absolute",
          bottom: "-44px",
          zIndex: 20,
          left: 0,
          right: 0,

          display: "flex", 
          justifyContent: "center", 
          // overflow: "visible",

          gap: 2 }}>
          <IconButton color="primary">
            {/* <MicIcon fontSize="large" /> */}
            <img src={MicIcon} alt="mic" style={{ width: "50px", height: "50px" }} />
          </IconButton>
          <IconButton color="primary">
            {/* <PlayCircleIcon fontSize="large" /> */}
            <img src={PlayCircleIcon} alt="play" style={{ width: "50px", height: "50px" }} />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TeacherCard;
