import { Box, Grid, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import WalletIcon from '@mui/icons-material/Wallet';
import { jwtDecode } from "jwt-decode";
import { NavLink } from "react-router-dom";

// components
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import BannerWithBg from "./../../components/BannerWithBg";
import CourseCard from "../../components/CourseCard";

// assets
import bg_b from "./../../assets/bg_b.png";
import bg_4 from "./../../assets/bg_4.svg";


import dataProvider from "../../dataProvider";
import Course from "../../types/Course";

import { Outlet } from "react-router"



const filterOptions = {
  allSubjects: [
    { label: "قرآن كريم", value: "Quran" },
    { label: "علوم العربية", value: "Arabic" },
    { label: "قواعد التجويد", value: "Tajweed" },
    { label: "شرح صحيح مسلم", value: "SahihMuslim" },
  ],
  allTeachers: [
    { label: "الشيخ عبد الرحمن", value: "SheikhAbdulRahman" },
    { label: "دكتور خالد محمود", value: "DrKhaledMahmoud" },
    { label: "الشيخ مصطفى عمر", value: "SheikhMustafaOmar" },
    { label: "الشيخ محمود السيد", value: "SheikhMahmoudElSayed" },
    { label: "دكتور خالد الهندي", value: "DrKhaledAlHindi" },
    { label: "الشيخ مراد حسين", value: "SheikhMoradHussein" },
  ],
  allSections: [
    { label: "قسم الرجال", value: "Men" },
    { label: "قسم النساء", value: "Women" },
  ],
};

interface FilterValues {
  subjects: string[];
  teachers: string[];
  sections: string[];
}

interface FilterFormProps {
  onFilterChange: (values: FilterValues) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({ onFilterChange }) => {
  return (
    <Formik<FilterValues>
      initialValues={{
        subjects: [],
        teachers: [],
        sections: [],
      }}
      onSubmit={(values) => {
        onFilterChange(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            display: "flex",
            width: "80%",
            flexDirection: "column",
          }}
        >
          {/* Subjects */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المادة</Typography>
            <FormGroup>
              {filterOptions.allSubjects.map((subject) => (
                <FormControlLabel
                  key={subject.value}
                  control={
                    <Checkbox
                      checked={values.subjects.includes(subject.value)}
                      onChange={() =>
                        setFieldValue(
                          "subjects",
                          toggleValue(values.subjects, subject.value)
                        )
                      }
                    />
                  }
                  label={subject.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Teachers */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">المعلم</Typography>
            <FormGroup>
              {filterOptions.allTeachers.map((teacher) => (
                <FormControlLabel
                  key={teacher.value}
                  control={
                    <Checkbox
                      checked={values.teachers.includes(teacher.value)}
                      onChange={() =>
                        setFieldValue(
                          "teachers",
                          toggleValue(values.teachers, teacher.value)
                        )
                      }
                    />
                  }
                  label={teacher.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Sections */}
          <FormControl
            component="fieldset"
            sx={{
              marginBottom: 2,
              border: "1px solid #4fbcc0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Typography variant="h6">القسم</Typography>
            <FormGroup>
              {filterOptions.allSections.map((section) => (
                <FormControlLabel
                  key={section.value}
                  control={
                    <Checkbox
                      checked={values.sections.includes(section.value)}
                      onChange={() =>
                        setFieldValue(
                          "sections",
                          toggleValue(values.sections, section.value)
                        )
                      }
                    />
                  }
                  label={section.label}
                />
              ))}
            </FormGroup>
          </FormControl>

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary">
            Apply Filters
          </Button>
        </Form>
      )}
    </Formik>
  );
};

// Utility function to toggle values in an array
const toggleValue = (array: string[], value: string): string[] => {
  return array.includes(value)
    ? array.filter((item) => item !== value)
    : [...array, value];
};

function CoursesPage() {
  const [courses, setCourses] = React.useState<any[]>([]);
  const [profile, setProfile] = React.useState<any>(null);

  const token = localStorage.getItem("token");
  const token_decoded: any = jwtDecode(token || "")
  console.log(token_decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"])
  const trainer_id = token_decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]

  React.useEffect(() => {
   
  }, []);

  return (
    <div style={{ width: "100vw" }}>

      <BannerWithBg bg={bg_b} text="البيانات الشخصية" />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 70px",
          width: "calc(100vw - 140px)",
          backgroundImage: `url(${bg_4})`,
        }}
      >
        <Stack 
        useFlexGap
        spacing={4} direction="row"
        width="100%"
        
        >
          {/* 9 3 */}
            <Stack
              spacing={2}
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#fff",
                marginBottom: 2,
                border: "1px solid #4fbcc0",
                backgroundColor: "#fff",
                // borderRadius: "10px",
                // padding: "10px",
                minHeight: "300px",
                minWidth: "250px",
                maxWidth: "25%",

              }}
            >
              <Stack
                direction="row"
                width="100%"
                spacing={2}
                useFlexGap
                alignItems="center"
                justifyContent="center"

              >

              <img src={bg_b} alt="bg_4" style={{ 
                width: "100px",
                height: "100px",
                // clipPath: "circle(50% at 50% 50%)",
                borderRadius: "50%",
                objectFit: "cover",
                // minHeight: "200px",
              }} />
              <Stack
              width="100%"
              spacing={1}
              useFlexGap
              paddingX={2}
              style={{
                color: "#134847",
              }}
              >
              {/* محمد عبد الرحمن السيد */}
              <Typography
                variant="h5"
                style={{
                  color: "#4FBCC0",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                
                {/* محمد عبد الرحمن السيد */}
                {profile?.fullName || "fullName"}
              </Typography>
              {/* import WalletIcon from '@mui/icons-material/Wallet'; */}
              <Chip variant="outlined" 
              // icon={<WalletIcon />} 
              style={{
                  // direction: "ltr",
                  borderRadius: "7px",
                  color: "#F7941D",
                  backgroundColor: "#FFF1DB",
                  border: "1px solid #f7941d",
                  width: "100%",
                }}
                label={
                (
                <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                >
                 <WalletIcon style={{
                  paddingInlineEnd: "5px",
                 }}/>


                <Typography
                variant="h5"
                style={{
                  color: "#F7941D",
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "16px",
                  direction: "rtl",
                }}
              >

                <span style={{ paddingInlineEnd: "5px" }}>


                {profile?.creditBalance || "0.00"}
                </span>
  
                 جنيها


              </Typography>
              
              </Stack>
              )

              } />
              {/* <Chip label="1220 جنيها" variant="outlined" icon={<WalletIcon />} /> */}
              {/* <Chip label="1220 جنيها" variant="outlined" icon={<WalletIcon />} /> */}


              </Stack>
              </Stack>
              {/* <Chip label="1220 جنيها" variant="outlined" icon={<WalletIcon />} /> */}

              {/* المواد العلمية */}
                <NavLink 
                to="/trainer-profile"
                end
                
                style={({isActive}) => ({
                width: "100%",
                backgroundColor: isActive ? "#f7941d" : "#F8F8FC",
                color: isActive ? "#fff" : "#134847",
                textDecoration: "none",
                borderRadius: "10px",

                })}
                >
                <Button 
                  variant="contained" 
                  style={{ 
                  width: "100%", 
                  backgroundColor: "inherit", 
                  color: "inherit",
                  boxShadow: "none",
                  }}
                >
                  المواد العلمية
                </Button>
                </NavLink>
                {/* البيانات الشخصية */}
                <NavLink 
                to="/trainer-profile/edit"
                style={({isActive}) => ({
                width: "100%",
                backgroundColor: isActive ? "#f7941d" : "#F8F8FC",
                color: isActive ? "#fff" : "#134847",
                textDecoration: "none",
                borderRadius: "10px",

                })}
                >
                <Button 
                  variant="contained" 
                  style={{ 
                  width: "100%", 
                  backgroundColor: "inherit", 
                  color: "inherit",
                  boxShadow: "none",
                  }}
                >                البيانات الشخصية
                </Button>
                </NavLink>
                {/* edit-available-time */}
                <NavLink 
                to="/trainer-profile/edit-available-time"
                style={({isActive}) => ({
                width: "100%",
                backgroundColor: isActive ? "#f7941d" : "#F8F8FC",
                color: isActive ? "#fff" : "#134847",
                textDecoration: "none",
                borderRadius: "10px",

                })}
                >
                <Button 
                  variant="contained" 
                  style={{ 
                  width: "100%", 
                  backgroundColor: "inherit", 
                  color: "inherit",
                  boxShadow: "none",
                  }}
                >   
                تعديل الأوقات المتاحة            

              </Button>

              </NavLink>

              {/* تغيير كلمة المرور */}
              <NavLink 
                to="/trainer-profile/change-password"
                style={({isActive}) => ({
                width: "100%",
                backgroundColor: isActive ? "#f7941d" : "#F8F8FC",
                color: isActive ? "#fff" : "#134847",
                textDecoration: "none",
                borderRadius: "10px",

                })}
                >
                <Button 
                  variant="contained" 
                  style={{ 
                  width: "100%", 
                  backgroundColor: "inherit", 
                  color: "inherit",
                  boxShadow: "none",
                  }}
                >                تغيير كلمة المرور
              </Button>
              </NavLink>


            </Stack>
            
               {/* <Stack
                spacing={5}
                direction="row"
                justifyContent="center"
                alignItems="center"
                useFlexGap
                paddingX={2}
                flexWrap="wrap"
              > */}
              <Stack
              spacing={2}
              style={{
                padding: "20px",
                borderRadius: "10px",
                width: "100%",
                // backgroundColor: "#fff",
                marginBottom: 2,
                border: "1px solid #4fbcc0",
                backgroundColor: "#fff",
                // borderRadius: "10px",
                // padding: "10px",
                minHeight: "300px",
                // minWidth: "250px",

              }}
            >
                {/* {[...courses, ...courses].map((course, index) => (
                  <CourseCard data={course} key={index} />
                ))} */}
                {/* aaaaaaaaaaaa */}
                <Outlet />
                {/* {JSON.stringify(token_decoded)} */}
              </Stack>
         
        </Stack>
      </Box>
      {/* <div style={{ height: "60vh" }} /> */}

    </div>
  );
}
export default CoursesPage;
