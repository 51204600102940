import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import dataProvider from '../../dataProvider';



// Define the validation schema using Yup
const validationSchema = Yup.object({
  // len8 1 Capital 1 small 1 number 1 special char
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Required'),
  newPassword: Yup.string()
  .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Required'),
  repeatPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

function FormField({ propId ,placeholder, label, type="text" }: any){
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
    maxWidth: "300px",

  };

  const textFieldStyle = {
    width: "100%",
    maxWidth: "300px",
    // marginBottom: "20px",
  };

  return (
    <>
    <Box component="label" sx={labelStyle} htmlFor={propId}>
      {/* البريد الإلكترونى */}
      {label}
    </Box>
    <Field name={propId}>
      {({ field }: FieldProps) => (
        <TextField
          {...field}
          id={propId}
          variant="outlined"
          type={type}
          placeholder={placeholder}
          sx={textFieldStyle}
        />
      )}
    </Field>
    <ErrorMessage name={propId}>
      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
    </ErrorMessage>
    </>
  )
}


const LoginForm: React.FC = () => {

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",
    maxWidth: "300px",
  };

  function handleSubmit(values: any, { setSubmitting }: any) {
    console.log(values)
    dataProvider.auth.change({ password: values.password, newPassword: values.newPassword }).then((res) => {
      console.log(res);
      setSubmitting(false);
    }).catch((err) => {
      console.log(err);
      setSubmitting(false);
    })
      
  }

  return (
    <Formik
      initialValues={{ password: '', newPassword: '', repeatPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <FormField  propId="password" placeholder="ادخل كلمة المرور" label="كلمة المرور" type="password" />
          <FormField  propId="newPassword" placeholder="ادخل كلمة المرور الجديدة" label="كلمة المرور الجديدة" type="password" />
          <FormField  propId="repeatPassword" placeholder="ادخل كلمة المرور الجديدة" label="تأكيد كلمة المرور الجديدة" type="password" />

          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            حفظ
          </Button>
          
          
        </Form>
      )}
    </Formik>
  );
};


export default function ChangePassword() {
  return (
    <div>
      <h2>تغيير كلمة المرور</h2>
      <LoginForm />
    </div>
  );
}