import logo from "./../logo.svg";
import { Stack, Typography, Grid, List, Box, useMediaQuery } from "@mui/material";

// assets
import PointIcon from "./../assets/point.svg";
import CallIcon from "./../assets/call-calling.svg";
import MapIcon from "./../assets/map.svg";
import FooterMask from "./../assets/footer_mask.svg";

import info from "./../data/info";

function FooterLink({ text }: { text: string }) {
  return (
    <Stack
      style={{ paddingBlockEnd: "20px" }}
      direction="row"
      useFlexGap
      alignItems="center"
    >
      <img
        src={PointIcon}
        alt="point"
        style={{ height: "20px", padding: "3px 0 0 10px" }}
      />
      {text}
    </Stack>
  );
}

function Footer() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <footer
      style={{
        backgroundColor: "#4FBCC0",
        minHeight: "460px",
        backgroundImage: `url(${FooterMask})`,
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        padding={4}
        paddingX={isMobile?4:10}
      >
        <Grid item xs={isMobile?12:6}>
          <Stack padding={2} spacing={2} useFlexGap>
            <div style={{ height: "138px" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  // paddingInlineStart: -5,
                }}
              />
            </div>
            <Typography
              style={{ color: "#fff", fontSize: "30px" }}
              // paddingInlineStart={2}
            >
              منصة حافظ
            </Typography>
            <Typography
              style={{ 
                width: "100%",
                color: "#fff", fontSize: "20px", textAlign: "justify" }}
              width="640px"
              // paddingInlineStart={2}
              maxWidth="80vw"
            >
              {`أنشئت لتعليم القرآن الكريم و احكام التلاوة لجميع الاعمار في أي مكان بالعالم من خلال الإنترنت ونضم فريقًا من المعلمين الأكفاء و الذى يعمل وفق أفضل الطرق التربوية و أحدث الوسائل التعليمية التى تناسب جميع المستوايات والقدرات تسعى دائما لتميز طلابها، والحفاظ على أوقاتهم وتنمية مهاراتهم`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={isMobile?12:6}>
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Grid item xs={isMobile?12:6}>
              <Box padding={2}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingBlockEnd: "15px",
                  }}
                >
                  خدماتنا
                </Typography>

                <Stack spacing={5} direction="row" useFlexGap>
                  <List style={{ color: "#fff", fontSize: 20, height: "80%" }}>
                    {[
                      "الرئيسية",
                      "الكورسات",
                      "المقالات",
                      "المعلمون",
                      "الباقات",
                    ].map((item) => (
                      <FooterLink text={item} key={item}/>
                    ))}
                  </List>

                  <List style={{ color: "#fff", fontSize: 20, height: "80%" }}>
                    {["من نحن", "طلب تسجيل كمعلم"].map((item) => (
                      <FooterLink text={item} key={item}/>
                    ))}
                  </List>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={isMobile?12:6}>
              <Box padding={2}>
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingBlockEnd: "20px",
                  }}
                >
                  تواصل معنا
                </Typography>
                <Stack spacing={2}>
                  <Stack
                    spacing={2}
                    direction="row"
                    useFlexGap
                    alignItems="center"
                  >
                    <img
                      src={MapIcon}
                      alt="MapIcon"
                      style={{ height: "30px", padding: "3px 0 0 10px" }}
                    />
                    <Typography variant="h6" component="div" color="#fff">
                      {/* 121, Vijay Nagar Heydrabaad */}
                      {info.address}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    useFlexGap
                    alignItems="center"
                  >
                    <img
                      src={CallIcon}
                      alt="call"
                      style={{ height: "30px", padding: "3px 0 0 10px" }}
                    />
                    <Typography variant="h6" component="div" color="#fff">
                      {info.phone}
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    useFlexGap
                    alignItems="center"
                  >
                    <img
                      src={CallIcon}
                      alt="call"
                      style={{ height: "30px", padding: "3px 0 0 10px" }}
                    />
                    <Typography variant="h6" component="div" color="#fff">
                      {/* info@example.com example@gmail.com */}
                      {info.email}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
