import { API_HOST } from "../config";

const Logout = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};

async function fetcher({ url, data, method, params }: any) {
  let fetchURL = API_HOST + url;

  if (params) {
    const urlParams = new URLSearchParams(params).toString();
    console.log(urlParams);
    fetchURL = fetchURL + "?" + urlParams;
  }
  const response = await fetch(fetchURL, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token")?`Bearer ${localStorage.getItem("token")}`:"",
    },
    body: data ? JSON.stringify(data) : null,
  });
  const responseData = await response.json();

  // if (response.status === 401) Logout();

  if (!response.ok) {
    throw responseData;
  }
  return responseData;
}
export default fetcher;
