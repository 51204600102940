import { Link } from "react-router-dom";
import { Stack } from "@mui/material";

function PageNotFound() {
  return (
    <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        padding={2}
    >
      <h1>Page Not Found</h1>
      <Link to="/" 
        style={{
            color: "blue",
            fontSize: "20px",
            fontWeight: "bold",
        }}
      >Go to Home</Link>
    </Stack>
  );
}
export default PageNotFound;
