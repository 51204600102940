// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";

// assets
import bg_b from "./../assets/bg_b.png";

function CoursesPage() {
  return (
    <div>
      <BannerWithBg bg={bg_b} text="register as teacher" />

      <div style={{ height: "60vh" }} />

    </div>
  );
}
export default CoursesPage;
