import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Formik,
  Form,
  Field,
  FieldProps,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';


// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import BannerWithBg from "./../components/BannerWithBg";

// assets
import bg_4 from "./../assets/bg_4.svg";
import bg_a from "./../assets/bg_a.png";

import dataProvider from '../dataProvider';



// Define the validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Required'),
});


const LoginForm: React.FC = () => {
  const labelStyle = {
    width: "100%",
    color: "#111",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "20px",
  };

  const textFieldStyle = {
    width: "100%",
    // marginBottom: "20px",
  };

  const buttonStyle = {
    width: "100%",
    height: "50px",
    backgroundColor: "#f7941d",
    marginTop: "20px",

  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
        dataProvider.auth.login({ email: values.email, password: values.password }).then((res) => {
          console.log(res);
      //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyY2E5M2U3Mi00OGU1LTRjZDgtYThmZS0yNzM1MzQxMTU2MjEiLCJqdGkiOiI3YzY0NGFlZC1lMWQ0LTRhNWMtYWM4Ni00ZDAxMDZhNGVlYzMiLCJpYXQiOiIxNzMzNjc0OTMxIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiIyY2E5M2U3Mi00OGU1LTRjZDgtYThmZS0yNzM1MzQxMTU2MjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoidHRAYWRtaW4uY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZW1haWxhZGRyZXNzIjoidHRAYWRtaW4uY29tIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVHJhaW5lciIsIm5iZiI6MTczMzY3NDkzMSwiZXhwIjoxNzMzNzE4MTMxLCJpc3MiOiJodHRwczovL2FwaS5oYWZlei5zYSIsImF1ZCI6Imh0dHBzOi8vaGFmZXouc2EifQ.pGlrNolrw3-vnfOdlurLFZ8qTthjHkLZo6TSPIi9HyI",
      //     "refreshToken": "CfDJ8EuqTQeFx51CgoBc1ovVQbP/aYGOXeTEKPUOzxAyZtkUrFhxd7bmLIApbVje7kcmu/KgDtPlU2LpsJnaHaqU3GDQHpBVyu77dRAnCuFMJUUh1AE9yfE+ijtNpx26ZhtVSLhXlW5x/1VmVPd1nK94ts/ySPTtuwmVWJDxbkCjxss7posm2XGG015mtYQQn2T0tFmEEkIupwGF70aLLs6bf1WzUnQhc6RPfyRmSW+RRKwK",
      //     "userEmail": "tt@admin.com",
      //     "tokenExpires": "2024-12-09T04:22:11.2086454Z",
      //     "roles": [
      //         "Trainer"
      //     ]
      // }
          localStorage.setItem("token", res.token);
          localStorage.setItem("refreshToken", res.refreshToken);
          localStorage.setItem("userEmail", res.userEmail);
          localStorage.setItem("tokenExpires", res.tokenExpires);
          // localStorage.setItem("roles", JSON.stringify(res.roles));
          localStorage.setItem("role", res.roles.includes("Trainer") ? "Trainer" : res.roles.includes("Student") ? "Student" : "");

          if (res.roles.includes("Trainer")) {
            // window.location.href = "/trainer";
            window.location.href = "/";
          }
          else if (res.roles.includes("Student")) {
            // window.location.href = "/student";
            window.location.href = "/student";
          }
          else {
            window.location.href = "/";
          }



          setSubmitting(false);
        }).catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Box component="label" sx={labelStyle} htmlFor="email">
            البريد الإلكترونى
          </Box>
          <Field name="email">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="email"
                variant="outlined"
                placeholder="ادخل البريد الإلكترونى"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="email">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>

          <Box component="label" sx={labelStyle} htmlFor="password">
            كلمة المرور
          </Box>
          <Field name="password">
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                id="password"
                type="password"
                variant="outlined"
                placeholder="ادخل كلمة المرور"
                sx={textFieldStyle}
              />
            )}
          </Field>
          <ErrorMessage name="password">
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>

          <Link to="/auth/forget-password" style={{
            color: "#134847",
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "end",
            marginTop: "10px",
          }}>
            نسيت كلمة المرور؟
          </Link>

          <Button type="submit" variant="contained" sx={buttonStyle} disabled={isSubmitting}>
            تسجيل الدخول
          </Button>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mt={2}
            sx={{
              textAlign: "center",
            }}
          >


          <Typography
            sx={{
              fontSize: "16px",
              color: "#134847",
              fontWeight: "600",
              // marginTop: "20px",
              textAlign: "center",
              paddingX: "10px",
              
            }}
          >
            لا املك اى حساب؟ 
          </Typography>
            <Link to="/auth/register" style={{ 
              color: "#4fbcc0",
              fontSize: "16px",
              fontWeight: "600",
              }}>
            إنشاء حساب جديد
            </Link>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};


function LoginPage() {
  const navLinks = [
    { to: "/", text: "الرئيسية" },
    { to: "/auth/login", text: "تسجيل الدخول" },
    { to: "/auth/forget-password", text: "فقد كلمة المرور" },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${bg_4})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const formContainerStyle = {
    minHeight: "450px",
    width: "65vw",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #4FBCC0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const formStyle = {
    width: "50%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };


  return (
    <div>
      <BannerWithBg text="تسجيل الدخول" bg={bg_a} navLinks={navLinks} />

      <Stack alignItems="center" justifyContent="center" height="658px" sx={backgroundStyle}>
        <Box sx={formContainerStyle}>
          <Box sx={formStyle}>
            <LoginForm />
          </Box>
        </Box>
      </Stack>

    </div>
  );
}


export default LoginPage;
