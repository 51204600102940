import Question from "../types/Question";


const questions: Question[] = [
    {
      question: "1هل يمكنني الإشتراك في أكثر من باقة ؟",
      answer: "1نعم يمكنك الإشتراك في أكثر من باقة",
    },
    {
      question: "2هل يمكنني الإشتراك في أكثر من باقة ؟",
      answer: "2نعم يمكنك الإشتراك في أكثر من باقة",
    },
    {
      question: "3هل يمكنني الإشتراك في أكثر من باقة ؟",
      answer: "3نعم يمكنك الإشتراك في أكثر من با",
    },
    {
      question: "2هل يمكنني الإشتراك في أكثر من باقة ؟",
      answer: "2نعم يمكنك الإشتراك في أكثر من باقة",
    },
    {
      question: "3هل يمكنني الإشتراك في أكثر من باقة ؟",
      answer: "3نعم يمكنك الإشتراك في أكثر من با",
    },
];

export default questions;
