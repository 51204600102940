
// components
import Header from "./../components/Header";
import Footer from "./../components/Footer";

// sections
import BestCourseSection from "./../components/BestCourseSection";
import QASection from "./../components/QASection";
import ReviewsSection from "./../components/ReviewsSection";
import ReviewsTSection from "./../components/ReviewsTSection";
import PrayerTimesSection from "./../components/PrayerTimesSection";
import BlogSection from "./../components/BlogSection";
import CoursesSection from "./../components/CoursesSection";
import TeachersSection from "./../components/TeachersSection";
import SubscriptionPlansSection from "./../components/SubscriptionPlansSection";
import QuranLearningSection from "./../components/QuranLearningSection";

import { Box, Stack } from "@mui/material";
import StudentCard from "./../components/StudentCard";
import {
  useEffect,
  useState,
} from "react"
import dataProvider from "../dataProvider";


function StudentSection(){
  const [students, setStudents] = useState([]);

  useEffect(() => {
    (async()=>{
      // TODO: get students
      // const response = await dataProvider.trainer.getTrainerSessions();
      // console.log(response);
      // setStudents(response.data);
    })();

  }, []);
return( <Box
  style={{
    padding: "20px",
    borderRadius: "10px",
    width: "100%",
    // backgroundColor: "#0f0",
  }}
>
   <Stack
    spacing={5}
    direction="row"
    justifyContent="center"
    alignItems="center"
    useFlexGap
    paddingX={2}
    flexWrap="wrap"
  >
    {students.map((student, index) => (
      <StudentCard data={student} key={index} />
    ))}
  </Stack>
</Box>);
}

function HomePage() {
  const isTrainer = localStorage.getItem("role") === "Trainer";
  const isStudent = localStorage.getItem("role") === "Student";

  if (isStudent) {
    return (
      <div style={{ width: "100vw", overflowX: "hidden" }}>
        <QuranLearningSection />
        <StudentSection />
        <BlogSection />
        <ReviewsTSection />
      </div>
    );
  }


  return (
    <div>

      <QuranLearningSection />
      {/* <StudentSection /> */}
      {

        /* المواد العلمية */
        isTrainer && (
          <StudentSection />
        )

      }

      { !isTrainer && (<BestCourseSection />) }
      { !isTrainer && (<CoursesSection />) }


      <BlogSection />

      { !isTrainer && (<TeachersSection />) }
      { !isTrainer && (<SubscriptionPlansSection />) }

      {/* <PrayerTimesSection /> */}

      { isTrainer ? (<ReviewsTSection />)  :(<ReviewsSection />) }

      { !isTrainer && (<QASection />) }

    </div>
  );
}

export default HomePage;
